<template>

<div>


  <!-- Filtros -->
  <div class="flex w-full mb-1">
    <b-field class="w-full">
      <b-input :placeholder="$t('name')"
          v-model="qNameFile"
          icon="search"
          icon-pack="fas"
          icon-right="sliders-h"
          icon-right-clickable
          @icon-right-click="openFilterAvancesFolderClick"
          @input="_HandleInputFilterFolderSearch(qNameFile)">
      </b-input>
    </b-field>

    <folder-files-filters-modal ref="refModalFilterFiles"
      :locations-list="[]"
      :with-button="false"
      :templates-list="templatesList"
      @epc-apply-filters="_HandleApplyFilters"
    />
  </div>

  <div class="panel" style="border-width: 0" v-if="false">
    <div class="flex items-center mb-4">

      <!-- por nombre -->
      <div class="w-1/3 mr-4">
        <b-field :label="$t('search_by_name')">
          <b-input
            v-model="filterName"
            :placeholder="$t('name')"
            type="search"
            icon="search"
            icon-pack="fas"
            ref="inputName"
            @input="checkInputTextFilter(filterName)">
          </b-input>
        </b-field>
      </div>

      <!-- por flujo -->
      <div class="w-1/3 mr-4">
        <b-field :label="$t('search_by_validation_flow')">
          <b-select ref="input"
                    v-model="filterTemplate"
                    :placeholder="$t('select')"
                    icon="search"
                    icon-pack="fas"
                    @input="loadDocumentsList()"
                    expanded
          >
            <option :value="null">{{$t('select')}}</option>
            <option v-for="flowOption in templatesList" :value="flowOption.id" :key="'flow_'+flowOption.id">
                      {{ flowOption.name }}
                    </option>
          </b-select>
        </b-field>
      </div>

      <!-- por estado -->
      <div class="w-1/3 mr-4">
        <b-field :label="$t('search_by_status')">
          <b-select ref="input"
                    v-model="filterStatus"
                    :placeholder="$t('status')"
                    icon="search"
                    icon-pack="fas"
                    @input="loadDocumentsList()"
                    expanded
          >
            <option :value="null">{{$t('validation_status_all')}}</option>
            <option value="99">{{$t('uncompleted')}}</option>
            <option value="0">{{$t('validation_status_pending')}}</option>
            <option value="1">{{$t('validation_status_pending_validation')}}</option>
            <option value="4">{{$t('validation_status_pending_answer')}}</option>
            <option value="2">{{$t('validation_status_completed')}}</option>
            <option value="3">{{$t('validation_status_rejected')}}</option>
            <option value="5">{{$t('validation_status_reopened')}}</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="flex items-center mb-4">
      <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
    </div>
  </div>



  <div class="py-4">
    <span class="text-blue-light font-bold ml-2" v-if="total>=0">{{ $t('total') }}: {{ total }}</span>
  </div>


  <!-- Loading... -->
  <div v-if="isLoading" class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
  </div>


  <div style="font-size: 14px" v-else>

    <!-- listado documentos -->
    <div v-for="document, index in documents" :key="'d_' + document.document_id" >

      <div class="w-full flex" :class="[ (index) % 2 ? 'bg-white' : 'bg-grey-lighter']">

        <div @click="showPreview(document, index)" class="cursor-pointer flex items-center">
          <b-icon pack="far" icon="file" size="is-medium" />
        </div>

        <div class="w-3/5 ml-2 items-center flex" >
          <div class="leading-4 py-2">
            <b-tooltip :label="pathName(document)" position="is-right" multilined>
              {{ document.name }}
            </b-tooltip>
            <span class="italic" v-if="document.desc_name"><br>{{ document.desc_name }}</span>
            <span class="text-xs text-blue-light font-bold ml-2"></span>
            <span class="text-grey-dark font-bold cursor-pointer" @click="goToActivity(document.activity_id)" style="font-size: 11px" v-if="document.activity_id"><br>{{document.activity_name}}</span>
            <span class="text-blue-light font-bold cursor-pointer" @click="goToValidation(document.related_validation.validation_id)" style="font-size: 11px" v-if="document.related_validation"><br>{{document.related_validation.validation_name}}</span>
            <span class="text-grey-dark " style="font-size: 11px" v-if="document.full_path"><br>{{document.full_path}}</span>
          </div>
        </div>
        <div class="w-1/5 ml-2 flex items-center px-2">
          <validation-status-label v-if="document.related_validation" :status="document.related_validation.status" class="w-full" style="vertical-align: middle"/>
        </div>

        <div class="w-1/5 ml-2 flex items-center">
          <div class="flex w-full">

            <!-- sin opcion 'mover' por el momento
            <b-dropdown aria-role="list" position="is-bottom-left" class="w-8 h-8">

              <template #trigger="{ active }">
                  <b-icon :icon="active ? 'ellipsis-v' : 'ellipsis-h'" pack="fas" size="is-small" class=""></b-icon>
              </template>
              <b-dropdown-item aria-role="listitem" @click="onMoveClick(document)" >
                <span class="text-xs">{{ $t('move_to') }}</span>
              </b-dropdown-item>

            </b-dropdown> -->
            <b-tooltip multilined position="is-top">
                <b-icon pack="fas" icon="info" />
                <template v-slot:content>
                  <b>Creación: </b>{{document.created_at}}
                  <br>
                  <b>Última modificación: </b>{{document.updated_at}}
              </template>
            </b-tooltip>

            <b-tooltip :label="document.user.fullname + ' (' + document.created_at + ')'" position="is-top" multilined>
              <div class="w-8 h-8 overflow-hidden">
                <b-icon pack="fas" icon="user" />
              </div>
            </b-tooltip>

            <b-tooltip :label="$t(documentDownloadTooltip('download_document', document))" position="is-top">
              <div class="w-8 h-8 overflow-hidden" @click="downloadDoc(document)">
                <b-icon pack="fas" icon="download" />
              </div>
            </b-tooltip>

            <b-tooltip :label="$t('display_versions')" position="is-top">
              <div v-if="showVersions && document.versions.length > 0"
                class="w-8 h-8 overflow-hidden" @click="openVersionsModal(document.document_id)" :disabled="document.versions.length <= 0 || !isOk(document)">
                <b-icon pack="fas" icon="history" />
              </div>
            </b-tooltip>

            <span class="dot" v-if="document.versions.length > 0">
              {{ document.versions.length }}
            </span>

          </div>
        </div>

        <!-- Modal para PREVIEW -->
        <entity-document-preview
            :entity-id="document.activity_id"
            :entity-type="'activity'"
            :document-id="document.document_id"
            ref="previewModal"
            :can-delete="user.id == document.user.user_id"
            :showVersions="showVersions"
            @reload-documents-list="() => $emit('reload-documents-list')"
        />

        <!-- Modal para las versiones -->
        <document-versions
            ref="versions_modal"
            :document="document"
            :entityId="document.activity_id"
            :entityType="'activity'"
            @reload-documents-list="reloadList"
        />

      </div>

    </div>


    <!-- Modal para mover documento a otra carpeta -->
    <!-- <document-move-folder
        ref="document_move_folder"
        :tree="selectedEntityFolderTree"
        :entityId="selectedEntityId"
        @reload-documents-list="reloadList"
    /> -->


    <!-- mensaje carpeta vacia -->
    <div v-if="total == -1" class="p-4 text-blue">
      <b-icon pack="fas" icon="search" /> Escribe una busqueda
    </div>
    <div v-else-if="total == 0" class="p-4 text-red">
      <b-icon pack="fas" icon="ban" /> {{ $t('no_results_for_this', [filterName]) }}
    </div>
  </div>


</div>

</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import EventBus from '~/plugins/bus'
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import entityDocumentPreview from "·/components/entity-document-preview";
  import documentVersions from "·/components/document-versions";
  import documentMoveFolder from "·/components/document-move-folder";
  import foldersFilters from "·/components/documents-folders/folders-filters";
  import validationStatusLabel from '·/components/validations/validation-status-label';
  import FolderFilesFiltersModal from '../../../components/documents-folders/folder-files-filters-modal.vue'

  export default {
    middleware: 'auth',
    title: 'activities_page_title',
    subtitle: 'activities_page_subtitle',
    menuOption: '2-2',

    components: {
      ScaleLoader,
      entityDocumentPreview,
      documentVersions,
      documentMoveFolder,
      foldersFilters,
      validationStatusLabel,
      FolderFilesFiltersModal
    },

    computed: {
      ...mapGetters({
        user: "auth/user",
        project: 'app/project',
      }),
    },

    data: () => ({
      isLoading: false,
      filterName: '',
      documents: [], // documentos resultados de la busqueda
      total: -1, // -1 sin ejecutar busqueda, 0 busqueda sin resultados
      showVersions: true,
      filterTemplate: null,
      filterStatus: null,
      templatesList: [],
      qNameFile: ''
    }),

    mounted() {
      this.loadTemplates()
    },

    methods: {

      checkInputTextFilter(filter) {
        if (filter.length >= 3 || this.filterTemplate || this.filterStatus) {
          this.loadDocumentsList()
        } else if (filter.length == 0) {
          this.documents = []
          this.total = -1
        }
      },


      cleanFiltersClicked() {
        this.filterName = '';
        this.filterTemplate = null;
        this.filterStatus = null;
        this.documents = []
        this.total = -1
      },

      async loadDocumentsList() {

        this.isLoading = true
        let self = this

        let args = {}

        if (this.$refs.refModalFilterFiles !== undefined) {
          args = this.$refs.refModalFilterFiles.getFilters();
        }

        if ( this.qNameFile != null ) {
          args.text = this.qNameFile
        }

        let params = {
          text: args.text,
          template_id: args.template_id,
          status: args.status,
        }

        await axios.get("/api/v2/project/" + this.project.id + "/documents",
          { params: params })
          .then(function(response) {

            if (response.data && response.data.documents) {
              self.documents = response.data.documents.sort(self.mySortByName);
              self.total = response.data.total
            }
          })
          .catch(function(error) {
            console.log(error);
          })
          .finally(function () {
            self.isLoading = false;
          });
      },

      async loadTemplates() {
        let args = {
          p: this.project.id,
          template_list: 1
        }
        const {data} = await axios.get('/api/v2/validations', {params: args});
        this.templatesList = data.templates
      },


      pathName(document) {
        let path = document.activity_name
        path += document.folder_name ? ' (' + document.folder_name + ')' : ''
        return path
      },


      // ordenar las carpetas por nombre
      mySortByName(a, b) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      },


      reloadList() {
        this.$emit('reload-documents-list')
      },


      showPreview(document, index) {
        this.$refs.previewModal[index].show(document);
        if (document.exif) {
          EventBus.$emit("load-locations", document.exif);
        }
      },


      openVersionsModal(docId) {
        // Asi ya no vale pasando un 'index'. BUG pasando index abre bien la primera vez, luego se corre el array de versions_modal y ya no funciona
        // this.$refs.versions_modal[index].show();

        // Arreglado buscando por document_id entre todos los componentes 'versions_modal'
        let pos = this.$refs.versions_modal.findIndex(value => { return value._props.document.document_id == docId})
        this.$refs.versions_modal[pos].show();
      },


      isOk(document){
        return document.status == 1;
      },


      documentDownloadTooltip(tooltip, document) {
        if(this.isOk(document))
          return tooltip;

        return 'document_still_processing';
      },


      async downloadDoc(document) {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + document.document_id;

        const {data} = await axios.get(url);

        if (data) {

          window.open(data.data);

        } else {
          this.$notify.error("error_download_document");
        }
      },


      onMoveClick(document) {

        this.$refs.document_move_folder.show(document);

      },


      filtersUpdated() {
        this.getActivitiesList();
      },

      goToActivity(activity_id) {
          this.$router.push({'name': 'activity.home', params: {activity_id}})
      },

      goToValidation(validation_id) {
        this.$router.push({'name': 'validation.home', params: {validation_id: validation_id}})
      },

      openFilterAvancesFolderClick() {
        this.$refs.refModalFilterFiles.show()
      },

      _HandleInputFilterFolderSearch(filter) {
        if (filter.length >= 3 || this.filterTemplate || this.filterStatus) {
          this.loadDocumentsList()
        } else if (filter.length == 0) {
          this.documents = []
          this.total = -1
        }
      },

      _HandleApplyFilters(args) {
        this.loadDocumentsList();
      },
    },

  };
</script>
